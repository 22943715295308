@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
--sidebar-flex:1.5;
--condiv-flex:6;
--fontsize-p:1.2rem;
--fontsize-nav:1.6rem;
--typing-font:3rem;
}
* {
margin:0;
padding: 0;
}
body {
font-family: 'Raleway';
}
.App {
display: flex;
width: 100vw;
min-height: 100vh;
}

.alice-carousel ul li img{
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.picture{
  box-shadow: 0 0 8px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.alice-carousel img{
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  display: block;
}

.alice-carousel__dots{
  padding-top: 10px;
  display: inline;
}

.alice-carousel__stage-item {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.alice-carousel__next-btn {
  text-align: cen;
}
.alice-carousel__wrapper {
  padding-top: 30px;
  max-width: 600px;
}
/* .condiv class is for the right side content container*/
.condiv {
  padding: 100px ;
  flex:6 1;
  flex:var(--condiv-flex) 1;

}
.home-info {
  padding-top: 10px;
}
.home
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
}
.profilepic
{
  border-radius: 50%;
  width: 200px;
  margin: 30px;
  margin-bottom: 80px;
  
 
}
.typingeffect
{
  font-size: 3rem;
  font-size: var(--typing-font);
  margin-bottom: 80px;
}

nav {
padding:60px 30px 0px 30px ;
flex:1.5 1;
flex:var(--sidebar-flex) 1;
background-image: url(/static/media/blue-abstract.f3d72047.jpg);

background-repeat: repeat !important;
background-size: contain !important;


}

nav ul {
  font-size:1.6rem;
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li {
  margin:40px;
}
a {
  text-decoration: none;
  color: white
}

.active {
font-weight: bolder;
}

.social {
margin-top: 70px;
position: relative; 
display: block;
bottom: 60px;
}
.social i {
padding-right: 30px;
font-size: 25px;
color: #9a9b9c;
}
.social i:hover {
color: #ffffff;
}

.subtopic {
  margin:10px 10px 30px 10px !important;
  }
p {
  font-size: 1.2rem;
  font-size: var(--fontsize-p);
}

h1,h2,h3,h4,h5,p {
  margin: 10px;
}

.contact-info{
  color: #0074d9;
}
.widecard {
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
}
.widecard:hover {
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.skillscard {
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
}
.skillscard:hover {
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 2px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.skills-ul {
  list-style-type: none;
}

.nav-resume {
  background-color: #7395ae;
  border-radius: 3px;
  margin-bottom: 5px;
  padding: 10px ;
  box-shadow: 0 5px 10px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  width: 100%;
  flex:6 1;
  flex:var(--condiv-flex) 1;
}
.resume-btns {
  padding: 10px 21px;
  margin: 5px;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.resume {
  box-shadow: 0 3px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.project-container{
  background-color: #7395ae;
  margin: 8px;
  margin-top: 24px;
  padding: 8px;
  width: 600px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 4px rgba(0,0,0,0.2);
  text-align: center;
  position: static;
}

.project-card{
  box-shadow: 5px 5px 8px 4px rgba(0,0,0,0.2);
  transition: 0.3s;
  margin:30px;
  border-radius: 5px;
  max-width: 420px;
  height: 450px;
  display: inline-block;
  background-color: #f2f3f4;
  position: static;
  
}

.project-card:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.project-image{
  max-width: 420px;
  border-radius: 3px 3px 0 0 ;
  position: static;
}


.project-language{
  font-weight: 600;
}





.card-content{
  padding: 6px;
  border-radius: 0 0 5px 5px;

  
}

.project-github a {
  color: #0074d9;
  padding-right: 16px;
}

.project-name a {
  color: #0074d9;
}

.featured-container {
  margin: 8px;
  margin-top: 24px;
  padding: 8px;
  width: 99%;
  overflow: hidden;
  background-color: #7395ae;
  border-radius: 5px;
  box-shadow: 1px 2px 3px 3px rgba(0,0,0,0.2);
  text-align: center;
  position: relative;
}

.featured-header{
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 18px;
}

.featured-card {
  box-shadow: 6px 6px 6px 3px rgba(0,0,0,.2);
  overflow: hidden;
  margin: 6 auto;
  display: block;
  text-align: center;
  box-sizing: border-box;
  height: 45px;
  transition: height .5s;
  width: 48%;
  float: left;
  background-color: #ffffff;
  border-radius: 5px;
}

.featured-card:hover {
  height: 280px;
}
